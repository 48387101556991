import React, { CSSProperties } from 'react';
import { IImageProps, Image } from '@alterpage/gatsby-plugin-image';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    container,
    content,
    header,
    titleText,
    circle,
    descriptionText,
    noCircle,
    hasImage,
    authorBox,
    authorRatio,
    authorImageBox,
    authorName,
    tagList,
    tagLink,
    egg,
} from './hero.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { TAccentColor } from '../../models/accent-color.model';
import { IAuthor } from '../../models/author.model';
import { ITag } from '../../models/tag.model';
import { useT } from '../../hooks/use-translation';

import Title, { ITitleProps } from '../atoms/title';
import Markdown from '../hoc/markdown';
import Semicircle, { TSemicircleShape } from '../atoms/semicircle';
import Tag from '../atoms/tag';
import Breadcrumbs from '../molecules/breadcrumbs';

type THeroCircleDisplay = 'none' | 'color' | 'image';

interface IHeroProps {
    className?: string;
    color?: TAccentColor;
    titleProps?: Omit<ITitleProps, 'className' | 'subtitle'>;
    description?: string;
    circleDisplay?: THeroCircleDisplay;
    imageProps?: Pick<IImageProps, 'media' | 'relation' | 'fallback' | 'useFallback'>;
    imageMobileProps?: Pick<IImageProps, 'media' | 'relation' | 'fallback' | 'useFallback'>;
    style?: CSSProperties;
    author?: IAuthor;
    tags?: ITag[];
    circleShape?: TSemicircleShape;
}

const Hero: React.FC<IHeroProps> = ({
    className = '',
    color = 'purple',
    titleProps,
    description,
    circleDisplay = 'color',
    imageProps,
    imageMobileProps,
    style,
    author,
    tags,
    circleShape = 'egg',
}) => {
    const { breadcrumbs } = usePageContext();
    const { t } = useT();
    const { media } = author || {};
    const circleDisplayClass = circleDisplayClasses[circleDisplay];
    const eggClass = circleShape === 'egg' && circleDisplay !== 'none' ? egg : '';

    return (
        <div
            className={`${container} ${grid} ${className} ${circleDisplayClass} ${eggClass}`}
            style={style}
        >
            <div className={content}>
                {author && (
                    <div className={authorBox}>
                        <Image media={media} ratioClass={authorRatio} className={authorImageBox} />
                        <p className={authorName}>
                            {t('author.label')}: <strong>{author.displayName}</strong>
                        </p>
                    </div>
                )}
                {breadcrumbs.length > 0 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                <div className={header}>
                    <Title className={titleText} size="large" weight="bold" {...titleProps} />
                    {tags && tags.length > 0 && (
                        <ul className={tagList}>
                            {tags.map((tag) => {
                                return (
                                    <li key={tag.tagId}>
                                        <Tag tag={tag} className={tagLink} />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <Semicircle
                        className={circle}
                        color={color}
                        orientation="bottom"
                        shape={circleDisplay === 'image' ? 'normal' : circleShape}
                        imageProps={imageProps}
                        imageMobileProps={imageMobileProps}
                    />
                </div>
                {description && <Markdown className={descriptionText}>{description}</Markdown>}
            </div>
        </div>
    );
};

const circleDisplayClasses: Record<THeroCircleDisplay, string> = {
    color: '',
    none: noCircle,
    image: hasImage,
};

export default Hero;
