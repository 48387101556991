// extracted by mini-css-extract-plugin
export var blue = "post-card-module--blue--qk3QH";
export var button = "post-card-module--button--5WunX";
export var container = "post-card-module--container--JsgyE";
export var imgBox = "post-card-module--img-box--VFLWm";
export var leadText = "post-card-module--lead-text--GNxAR";
export var line = "post-card-module--line--Rzitd";
export var linkBox = "post-card-module--link-box--PA7Jm";
export var pink = "post-card-module--pink--L3Mpg";
export var purple = "post-card-module--purple--rD3DI";
export var ratio = "post-card-module--ratio--ddk1k";
export var tagList = "post-card-module--tag-list--l+14j";
export var titleText = "post-card-module--title-text--0XM4e";
export var yellow = "post-card-module--yellow--YtQnJ";