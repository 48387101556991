import React from 'react';
import { Script } from 'gatsby';
import { IBreadcrumb } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { list, item } from './breadcrumbs.module.scss';

import Crumb from '../atoms/crumb';

interface IBreadcrumbsProps {
    className?: string;
    breadcrumbs: IBreadcrumb[];
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ className = '', breadcrumbs }) => {
    const structuredData = getBreadcrumbsStructuredData(breadcrumbs);
    return (
        <>
            <Script type="application/ld+json">{structuredData}</Script>
            <ol className={`${list} ${className}`}>
                {breadcrumbs.map((breadcrumb, index) => {
                    const isLast = breadcrumbs.length - 1 === index;
                    return (
                        <li className={item} key={`breadcrumb-item-${breadcrumb.pathname}`}>
                            <Crumb breadcrumb={breadcrumb} isLast={isLast} />
                        </li>
                    );
                })}
            </ol>
        </>
    );
};

function getBreadcrumbsStructuredData(breadcrumbs: IBreadcrumb[]) {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((breadcrumb, index) => {
            return {
                '@type': 'ListItem',
                position: index + 1,
                name: breadcrumb.label,
                ...(index < breadcrumbs.length - 1
                    ? {
                          item: `${process.env.BASE_URL}${breadcrumb.pathname}`,
                      }
                    : {}),
            };
        }),
    });
}

export default Breadcrumbs;
