import React from 'react';
import { Link } from 'gatsby';
import { IBreadcrumb } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { crumb, last } from './crumb.module.scss';

interface ICrumbProps {
    className?: string;
    breadcrumb: IBreadcrumb;
    isLast?: boolean;
}

const Crumb: React.FC<ICrumbProps> = ({ className = '', breadcrumb, isLast }) => {
    if (isLast) {
        return (
            <p className={`${crumb} ${last} ${className}`} title={breadcrumb.label}>
                {breadcrumb.label}
            </p>
        );
    }

    return (
        <Link className={`${crumb} ${className}`} to={breadcrumb.pathname} title={breadcrumb.label}>
            {breadcrumb.label}
        </Link>
    );
};

export default Crumb;
