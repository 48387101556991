export type TRelationKey =
    | 'mainImage'
    | 'secondaryImage'
    | 'mainImageMobile'
    | 'coverImage'
    | 'doc'
    | 'projectMockupMobile'
    | 'projectMockupMobileSecondary'
    | 'projectMockupDesktop'
    | 'projectMockupDesktopSecondary'
    | 'projectPalette'
    | 'projectPaletteSecondary'
    | 'projectTypography'
    | 'projectTypographySecondary'
    | 'projectLogo'
    | 'projectRealizationCover';

export type TRelations = Record<TRelationKey, string>;

export const relations: TRelations = {
    mainImage: 'main-image',
    secondaryImage: 'secondary-image',
    mainImageMobile: 'mobile-image',
    coverImage: 'ap-cover-image',
    projectMockupMobile: 'ap-mockup-mobile',
    projectMockupMobileSecondary: 'ap-mockup-mobile-secondary',
    projectMockupDesktop: 'ap-mockup-desktop',
    projectMockupDesktopSecondary: 'ap-mockup-desktop-secondary',
    projectPalette: 'ap-palette',
    projectPaletteSecondary: 'ap-palette-secondary',
    projectTypography: 'ap-typography',
    projectTypographySecondary: 'ap-typography-secondary',
    projectLogo: 'ap-logo',
    projectRealizationCover: 'ap-realization',
    doc: 'ap-pdf',
};
