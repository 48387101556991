import React from 'react';
import { Link } from 'gatsby';

import { link } from './tag.module.scss';
import { ITag } from '../../models/tag.model';

interface ITagProps {
    className?: string;
    tag: ITag;
}

const Tag: React.FC<ITagProps> = ({ className = '', tag }) => {
    return (
        <Link to={`tag-${tag.pathname}`} className={`${link} ${className}`}>
            #{tag.name}
        </Link>
    );
};

export default Tag;
