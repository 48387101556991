import React from 'react';
import { graphql } from 'gatsby';
import { IPageContext, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { layout, hero, section, list, item, card } from './blog.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IPost, IPostCard } from '../models/post.model';
import { ITag } from '../models/tag.model';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';
import { getPostStructuredData } from '../utils/get-post-structured-data';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import PostCard from '../components/molecules/post-card';

interface IBlogPageProps {
    readonly data: {
        allPost: IQueryAllResult<IPostCard>;
        allTagPost: IQueryAllResult<IPostCard>;
        tag: ITag;
    };
}

const BlogPage: React.FC<IBlogPageProps> = ({ data }) => {
    const pageContext = usePageContext() as IPageContext & { tagId?: number | null };
    const { tagId } = pageContext;
    const { t } = useT();
    const { allPost, allTagPost, tag } = data;

    const posts = getNodes(allPost);
    const tagPosts = getNodes(allTagPost);

    const isTagPage = tagId && tag;
    const title = isTagPage ? `#${tag.name}` : t('blog.hero.title');
    const postsToView = isTagPage ? tagPosts : posts;

    return (
        <MainLayout className={layout} showFloatingCircles={true}>
            <Hero className={hero} titleProps={{ children: title, Tag: 'h1' }} color="purple" />
            <Section className={section} circleDisplay="none" gridType="narrow" height="auto">
                <ul className={list}>
                    {postsToView.map((post) => {
                        return (
                            <li key={`post-item-${post.postId}`} className={item}>
                                <PostCard className={card} postCard={post} />
                            </li>
                        );
                    })}
                </ul>
            </Section>
        </MainLayout>
    );
};

export function getBlogStructuredData(posts: IPost[], pageContext: IPageContext) {
    const postsData = [];
    for (let i = 0; i < 10; i++) {
        postsData[i] = getPostStructuredData(posts[i]);
    }

    const { meta, pathname } = pageContext;
    const title = meta.find((item) => item.name === 'title')?.content;
    const description = meta.find((item) => item.name === 'description')?.content;

    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Blog',
        name: title,
        url: `${process.env.BASE_URL}${pathname}`,
        description: description,
        blogPost: postsData,
    });
}

export const query = graphql`
    query BlogPage($locale: String!, $tagId: Int) {
        allPost(
            filter: { locale: { eq: $locale } }
            sort: { fields: [isPromoted, publishedAt], order: [DESC, DESC] }
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        tag(locale: { eq: $locale }, tagId: { eq: $tagId }) {
            ...tagFields
        }
        allTagPost: allPost(
            filter: { locale: { eq: $locale }, tags: { elemMatch: { tagId: { eq: $tagId } } } }
            sort: { fields: [isPromoted, publishedAt], order: [DESC, DESC] }
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
    }
`;

export default BlogPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
