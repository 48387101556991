import { getMediaByRelation } from '@alterpage/gatsby-plugin-image';

import { IPost } from '../models/post.model';
import { relations } from '../config/relations';
import { getStructuredDataFormattedDate } from './get-structured-data-formatted-date';

export function getPostStructuredData(post: IPost) {
    const mediaItem = getMediaByRelation({ media: post.media, relation: relations.mainImage });
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        author: {
            '@type': 'Person',
            name: post.author?.displayName,
        },
        datePublished: getStructuredDataFormattedDate(post.publishedAt),
        headline: post.title,
        image: [mediaItem?.url],
        url: `${process.env.BASE_URL}${post.pathname}`,
    });
}
