// extracted by mini-css-extract-plugin
export var authorBox = "hero-module--author-box--K+jCB";
export var authorImageBox = "hero-module--author-image-box--xayhC";
export var authorName = "hero-module--author-name--D3KCy";
export var authorRatio = "hero-module--author-ratio--DRRxs";
export var circle = "hero-module--circle--B50yw";
export var container = "hero-module--container--h4NsC";
export var content = "hero-module--content--URWYR";
export var descriptionText = "hero-module--description-text--l5dor";
export var egg = "hero-module--egg--+ihJ9";
export var hasImage = "hero-module--has-image--DpbHZ";
export var header = "hero-module--header--CfoKa";
export var noCircle = "hero-module--no-circle--clGwK";
export var tagLink = "hero-module--tag-link--SLh38";
export var tagList = "hero-module--tag-list--zKPKp";
export var titleText = "hero-module--title-text--OuEUJ";