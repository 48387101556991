import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    linkBox,
    imgBox,
    ratio,
    line,
    titleText,
    leadText,
    button,
    tagList,
    yellow,
    purple,
    pink,
    blue,
} from './post-card.module.scss';
import { relations } from '../../config/relations';
import { IPostCard } from '../../models/post.model';
import { TAccentColor } from '../../models/accent-color.model';
import { useT } from '../../hooks/use-translation';

import Button from '../atoms/button';
import Tag from '../atoms/tag';

interface IPostCardProps {
    className?: string;
    TitleTag?: React.ElementType;
    postCard: IPostCard;
}

const PostCard: React.FC<IPostCardProps> = ({ className = '', TitleTag = 'h2', postCard }) => {
    const { t } = useT();
    const { media, tags, pathname, color, title, lead } = postCard;

    const colorClass = colorClasses[color || 'purple'];

    return (
        <article className={`${container} ${className} ${colorClass}`}>
            <Link className={linkBox} to={pathname}>
                <Image
                    media={media}
                    className={imgBox}
                    ratioClass={ratio}
                    relation={relations.coverImage}
                />
                <span className={line} />
                <TitleTag className={titleText}>{title}</TitleTag>
                <p className={leadText}>{lead}</p>
            </Link>
            {tags.length > 0 && (
                <ul className={tagList}>
                    {tags.map((tag) => {
                        return (
                            <li key={`tag-${tag.tagId}`}>
                                <Tag tag={tag} />
                            </li>
                        );
                    })}
                </ul>
            )}
            <Button className={button} as="link" to={pathname} color="light">
                {t('button.see.post')}
            </Button>
        </article>
    );
};

const colorClasses: Record<TAccentColor, string> = {
    yellow: yellow,
    purple: purple,
    pink: pink,
    blue: blue,
};

export default PostCard;
